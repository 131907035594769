import React from "react";
import PropTypes from "prop-types";
import challengedIcon from "../../../../assets/icons-v2/challenged.png";
import CustomSvg from "../../../common/custom-svg/custom-svg";
import Modal from "react-bootstrap/Modal";
import AppButton from "../../../common/buttons/app-button";
import { get } from "lodash";
import useGAEvent from "../../../../hooks/useGAEvent";
import "../challenged-modal.scss";
import clsx from "clsx";

/**
 * ChallengedModalBody component
 * @param {Object} props - Component props
 * @param {boolean} props.isAuthenticated - Indicates if the user is authenticated
 * @param {Function} props.handleRegisterLinkClick - Function to handle register link click
 * @param {Object} props.challengeData - Data related to the challenge
 * @param {string} props.challengeId - The ID of the challenge
 * @returns {JSX.Element} ChallengedModalBody component
 */
export default function ChallengedModalBody({
    isAuthenticated,
    handleRegisterLinkClick,
    challengeData,
    challengeId,
}) {
    const property = get(challengeData, "property", {});
    const challengerName = get(challengeData, "challenger.name", "");
    const challengerPoints = get(challengeData, "challenger.points", "");
    const area = get(property, "Area.name", "");
    const bedrooms = get(property, "bedrooms", 0);
    const propertyType = get(property, "property_type", "");
    const { sendEvent } = useGAEvent();

    /**
     * Handle accepting the challenge
     */
    const handleAcceptChallenge = () => {
        sendEvent("challenge_accepted", {
            isAuthenticated,
            challengeId,
        });

        window.location.href = `/classic/challenge?challenge_id=${challengeId}`;
    };

    return (
        <>
            <Modal.Body className="pt-4 pb-0 px-4">
                <div className="challenged-modal-body">
                    <h5 className="app-text-title text-center">
                        You've been challenged by <span className="challenger-name">{challengerName}</span>!
                    </h5>
                    <div className="slide-img-wrapper">
                        <CustomSvg src={challengedIcon} size={{ height: 120, width: "auto" }} />
                    </div>
                    <p className="guess-the-price-text fs-6">GUESS THE PRICE</p>
                    <p className="property-info-text fs-5 px-4">
                        <b>
                            {bedrooms}br {propertyType}{" "}
                        </b>
                        in <b>{area}</b>
                    </p>
                    <p className="mt-4 fs-4">
                        Can you beat {challengerName}'s <b>{challengerPoints}pts</b>?
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer
                className={clsx("d-flex flex-column gap-3", {
                    "pb-5": isAuthenticated,
                    "pb-3": !isAuthenticated,
                })}
            >
                <AppButton
                    id="nextBtn"
                    type="button"
                    className="w-75 mx-auto m-0"
                    variant="solid"
                    theme="purple"
                    onClick={handleAcceptChallenge}
                >
                    Challenge Accepted
                </AppButton>
                {!isAuthenticated && (
                    <AppButton
                        type="button"
                        className="w-75 mx-auto m-0 fw-normal app-text-link align-self-center"
                        variant="bordered"
                        theme="purple"
                        onClick={handleRegisterLinkClick}
                    >
                        Already registered? Log in
                    </AppButton>
                )}
            </Modal.Footer>
        </>
    );
}

// PropTypes for the ChallengedModalBody component
ChallengedModalBody.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleRegisterLinkClick: PropTypes.func.isRequired,
    challengeData: PropTypes.object.isRequired,
    challengeId: PropTypes.string.isRequired,
};
