/**
 * CONFIGURATION file to deploy the correct variables for each environment
 * new environment can be added to the scripts to the package.json file
 * for example:
 *   'start': 'REACT_APP_STAGE=local npm-run-all -p watch-css start-js',
 *   'build': 'npm run build-css && react-scripts build',
 *   'build-dev': 'REACT_APP_STAGE=dev react-scripts build',
 *   'build-prod': 'REACT_APP_STAGE=prod react-scripts build',
 *   'build-qa': 'REACT_APP_STAGE=qa react-scripts build',
 *
 * The common constant will have the common variables between the environments
 */

/**
 * The variables to use in ENV environment when is run locally
 */
const local = {
    API_URL: `http://localhost:${process.env.PORT || 5000}`,
    ONESIGNAL_ID: "63e60876-42b1-45bd-a887-310ac77212ba", // Defaults to staging OneSignal ID
    BASE_URL: "http://localhost:3000",
    LIVE_VARIANT_BASE_URL: "http://localhost:3000",
};

/**
 * The variables to use in ENV environment when is run in STAGING environment
 */
const staging = {
    API_URL: "http://localhost:3000",
    ONESIGNAL_ID: "63e60876-42b1-45bd-a887-310ac77212ba", // OneSignal ID for staging
    BASE_URL: "https://priceme-staging-7b63d65502e9.herokuapp.com",
    LIVE_VARIANT_BASE_URL: "https://variant-test-staging-b913719c7bb5.herokuapp.com",
};

/**
 * The variables to use in PROD environment
 */
const prod = {
    API_URL: "http://localhost:3000",
    ONESIGNAL_ID: "a351a310-e06a-4db9-b112-8406b4c81795", // OneSignal ID for prod
    BASE_URL: "https://www.priceme.game",
    LIVE_VARIANT_BASE_URL: "https://www.priceme.game",
};

/**
 * The common variables to use in any environment
 */
const common = {
    GOOGLE_MAPS_API_KEY: "AIzaSyBOgZ5nkY07RcZqf0ZYp9G0bde-EzPskKY",
    GOOGLE_MAP_ID: "472a1533198e7b01",
    DAILY_PROPERTIES: 3,
    CHALLENGE_PROPERTIES: 3,
    AUTH0: {
        domain: "dev-kb71sgmlh0p32db1.us.auth0.com",
        client_id: "mAd5gwcuDReAmdYcplS3RJudzCdeseWi",
    },
};

const config = () => {
    let config;

    if (process.env.REACT_APP_STAGE === "production") {
        config = prod;
    } else if (process.env.REACT_APP_STAGE === "staging") {
        config = staging;
    } else {
        config = local;
    }

    return {
        ...common,
        ...config,
        MAXIMUM_GUESS: common.DAILY_PROPERTIES + common.CHALLENGE_PROPERTIES,
    };
};

export default config();
