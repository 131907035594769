import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";

import useLocalStorage from "../../hooks/useLocalStorage";
import useGAEvent from "../../hooks/useGAEvent";
import { LIVE_VARIANT_VERSIONS } from "../../constants/live-variant-versions";

import "./choose-game.scss";

const ChooseGame = () => {
    const [preferredVersion, setPreferredVersion] = useLocalStorage("live-variant-preferred-version");
    const navigate = useNavigate();
    const { sendEvent } = useGAEvent();

    const handleClick = (newVersion) => {
        const previousVersion = find(LIVE_VARIANT_VERSIONS, (version) => version.url === preferredVersion);
        sendEvent("switch_game", {
            version: previousVersion?.gaVersion ?? "classic",
            newVersion: newVersion?.gaVersion ?? "classic",
        });

        if (newVersion === "classic") {
            setPreferredVersion(newVersion);
            window.location.href = "/classic";
        } else {
            setPreferredVersion(newVersion?.url);
            navigate(`/${newVersion?.url}`);

            // do a reload when previous verson is classic to update header
            if (preferredVersion === "classic") {
                window.location.reload();
            }
        }
    };

    return (
        <div className="choose-game-container">
            <p className="app-text-title text-center welcome-text">Welcome to PriceMe</p>
            <p className="choose-your-game-text text-center">Choose your game</p>

            {Object.keys(LIVE_VARIANT_VERSIONS).map((version) => {
                const currentVersion = LIVE_VARIANT_VERSIONS[version];

                return (
                    <div
                        className={clsx(
                            "d-flex mb-4 gap-3 info-container",
                            currentVersion?.disabled && "disable-text"
                        )}
                        onClick={() => handleClick(currentVersion)}
                    >
                        <div className="choose-game-image-container">
                            <img
                                style={{
                                    width: currentVersion.icon.width,
                                    height: currentVersion.icon.height,
                                }}
                                src={currentVersion.icon.src}
                                alt={currentVersion.title}
                            />
                        </div>

                        <div className="choose-game-info-text">
                            <p>
                                <b>{currentVersion.title}</b>
                            </p>
                            <p>{currentVersion.description}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ChooseGame;
