import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { find } from "lodash";
import { LIVE_VARIANT_VERSIONS } from "../../../constants/live-variant-versions";
import { resolveLink } from "../../../services/PropertyService";
import useLocalStorage from "../../../hooks/useLocalStorage";
import NotFound from "./NotFound";

const GameRouter = () => {
    const { version, date, propertyDetails } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [, setShortLink] = useLocalStorage("short-link");
    const [GameComponent, setGameComponent] = useState(null);

    useEffect(() => {
        const handleRedirect = async () => {
            if ((!date || !propertyDetails) && !["choose-game", "classic"].includes(version)) {
                try {
                    const chicagoTimeZone = "America/Chicago";
                    const currentDate = moment.tz(new Date(), chicagoTimeZone).format("YYYY-MM-DD");

                    const response = await resolveLink({ play_date: currentDate });
                    const longLink = response?.data?.property?.long_link;

                    const shortLink = response?.data?.property?.short_link;

                    if (shortLink) setShortLink(shortLink);

                    if (longLink) {
                        navigate(`/${version}${longLink}`, { replace: true });
                        return;
                    }
                } catch (error) {
                    console.error("Error resolving link:", error);
                }
            } else {
                try {
                    const play_date = moment(date, "YYMMDD").format("YYYY-MM-DD");
                    const response = await resolveLink({ play_date });
                    const shortLink = response?.data?.property?.short_link;

                    if (shortLink) setShortLink(shortLink);
                } catch (error) {
                    console.error("Error setting short link:", error);
                }
            }

            const matchedVersion = find(LIVE_VARIANT_VERSIONS, (ver) => ver.url === version);
            setGameComponent(matchedVersion?.component || null);
            setIsLoading(false);
        };

        handleRedirect();
    }, [date, propertyDetails, version]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) return null;

    if (!GameComponent) {
        console.error(`No component found for version: ${version}`);
        return <NotFound />;
    }

    return GameComponent;
};

export default GameRouter;
