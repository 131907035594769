import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import moment from "moment-timezone";
import clsx from "clsx";
import { X } from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";

import AppButton from "../../../../components/common/buttons/app-button";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";
import CustomSvg from "../../../../components/common/custom-svg/custom-svg";
import ResponsiveWrapper from "../../../../components/common/ResponsiveWrapper";
import FbLogo from "../../../../assets/icons-v2/facebook_logo.png";
import RedditLogo from "../../../../assets/icons-v2/reddit_logo.png";
import PriceTagIcon from "../../../../assets/icons-v2/price-tag-icon.png";
import useGAEvent from "../../../../hooks/useGAEvent";
import useLocalStorage from "../../../../hooks/useLocalStorage";

/**
 * A modal that displays the user's daily score and provides feedback based on the score.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {number} props.score - The user's score.
 * @param {string} props.actualPrice - The actual price of the property.
 * @param {Object} props.version - The version of live-variant.
 * @param {Function} props.handleClose - The function to close the modal.
 * @param {JSX.Element} props.puzzleResult - The component that displays the live variant puzzle result
 * @returns {JSX.Element}
 */
export default function PuzzleDailyScoreModal({
    show,
    score,
    actualPrice,
    version,
    handleClose,
    handleShare,
    puzzleResult,
}) {
    const [timeRemaining, setTimeRemaining] = useState("Loading..."); // Default loading state
    const { sendEvent } = useGAEvent();
    const [visitorStatus] = useLocalStorage("visitor-status");
    const mailtoBase =
        "mailto:updates@priceme.app?subject=Sign%20me%20up%20for%20PriceMe%20Updates&body=Notify%20me%20each%20morning%20when%20the%20latest%20PriceMe%20is%20live!%0A%0A";
    const mailtoLink = `${mailtoBase}${version?.url}`;

    /**
     * Handles the closing of the live-variant daily score modal.
     */
    const handleCloseModal = () => {
        sendEvent("daily_score_close", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });
        handleClose();
    };

    const handleGetNotified = () => {
        sendEvent("next_priceme_notified", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });
    };

    const handleShareCommunity = (community) => {
        sendEvent("share_community", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus: visitorStatus || "new",
            community,
        });

        // Copy score
        handleShare(null /* event */, true /* skipToast */);

        toast.success("Copied score to clipboard!", {
            duration: 1000, // 1 second
        });

        setTimeout(() => {
            if (community === "reddit") {
                window.open("https://www.reddit.com/r/PriceMe/", "_blank");
            } else {
                // facebook
                window.open("https://www.facebook.com/groups/pricemegame", "_blank");
            }
        }, 1000); // 1 second delay
    };

    /**
     * Updates the countdown timer dynamically to count down to midnight CST.
     */
    useEffect(() => {
        const updateCountdown = () => {
            const now = moment().tz("America/Chicago");
            const midnight = moment().tz("America/Chicago").endOf("day");

            const diff = moment.duration(midnight.diff(now));
            const hours = diff.hours().toString().padStart(2, "0");
            const minutes = diff.minutes().toString().padStart(2, "0");
            const seconds = diff.seconds().toString().padStart(2, "0");

            setTimeRemaining(`${hours}hrs, ${minutes}m, ${seconds}s`);
        };

        updateCountdown(); // Initialize immediately
        const interval = setInterval(updateCountdown, 1000);
        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    useEffect(() => {
        sendEvent("daily_score_view", {
            userScore: score,
            liveVariantVersion: version?.gaVersion,
            visitorStatus,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ResponsiveWrapper
            centered
            backdrop="static"
            data-cy="live-variant-daily-score-modal"
            keyboard={false}
            show={show}
            onHide={handleCloseModal}
            className="select-location-modal"
        >
            <div className="tw-flex tw-justify-end tw-pt-2 tw-px-2">
                <X size={30} onClick={handleCloseModal} />
            </div>
            <Modal.Body
                className={clsx("tw-text-center tw-flex tw-flex-col tw-items-center tw-gap-4", {
                    "!tw-px-[55px]": isMobile,
                })}
            >
                <div>
                    <p className="tw-text-base tw-text-[#27273B] tw-mb-0">
                        The next PriceMe will be available in
                    </p>
                    <div className="tw-h-[40px] tw-items-center tw-flex tw-justify-center">
                        <span className="!tw-text-xl !tw-font-bold !tw-font-merriweather tw-mb-0 tw-bg-[#D9D9D9] tw-px-6 tw-py-2 tw-rounded-[10px]">
                            {timeRemaining}
                        </span>
                    </div>

                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-1">
                        <img className="tw-w-[30px] tw-my-2" src={PriceTagIcon} alt="star-icon" />
                        {/* Get notified mailto link */}
                        <a
                            href={mailtoLink}
                            className="!tw-text-[#3B166A] tw-text-base !tw-font-medium"
                            onClick={handleGetNotified}
                        >
                            Get notified
                        </a>
                    </div>
                </div>

                <hr className="tw-w-full tw-border-t tw-border-black tw-opacity-100 tw-my-0" />

                <div className="tw-text-center tw-flex tw-flex-col tw-items-center">
                    <p className="!tw-font-merriweather !tw-font-bold !tw-text-[24px] tw-mb-0">
                        Send to a friend
                    </p>
                    <p className="tw-text-[#27273B] !tw-text-[12px] !tw-font-noto">
                        Know someone who needs to see this listing?
                    </p>
                    <div className="tw-bg-[#2E2E2E] tw-py-4 tw-px-8 tw-rounded-[10px] tw-mb-2">
                        {puzzleResult}
                    </div>
                    <AppButton
                        variant="solid"
                        theme="gold"
                        className="modal-button !tw-w-[200px] !tw-mt-0"
                        onClick={handleShare}
                    >
                        Share Score
                    </AppButton>
                </div>

                <hr className="tw-w-full tw-border-t tw-border-black tw-opacity-100 tw-my-0" />

                <div className="tw-text-center tw-flex tw-flex-col tw-items-center tw-text-[#27273B]">
                    <p className="!tw-font-merriweather !tw-font-bold !tw-text-[20px] tw-mb-0">
                        Join the Conversation
                    </p>
                    <p className="!tw-text-[16px] !tw-font-noto">
                        Brag, vent, and discuss with the PriceMe Community
                    </p>

                    <div className="tw-flex tw-gap-1 tw-justify-center tw-items-center">
                        <div
                            className="tw-flex tw-flex-col tw-items-center tw-px-4 tw-cursor-pointer"
                            onClick={() => handleShareCommunity("facebook")}
                        >
                            <CustomSvg src={FbLogo} size={{ height: 35, width: 35 }} />
                            <p className="tw-text-[10px] tw-text-[#27273B] tw-font-noto">
                                Share in the Facebook Group
                            </p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-items-center tw-px-4 tw-cursor-pointer">
                            <CustomSvg
                                src={RedditLogo}
                                size={{ height: 35, width: 35 }}
                                onClick={() => handleShareCommunity("reddit")}
                            />
                            <p className="tw-text-[10px] tw-text-[#27273B] tw-font-noto">
                                Share in the Reddit Sub
                            </p>
                        </div>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center tw-mb-6">
                    <CustomButton
                        className="tw-text-base tw-bg-[#BB84FF] tw-text-[#27273B] tw-w-[200px] tw-rounded-[10px]"
                        text="More games"
                        handleClick={() => (window.location.href = "/")}
                    />
                </div>
            </Modal.Body>
        </ResponsiveWrapper>
    );
}

PuzzleDailyScoreModal.propTypes = {
    show: PropTypes.bool.isRequired,
    score: PropTypes.number.isRequired,
    actualPrice: PropTypes.string.isRequired,
    version: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    puzzleResult: PropTypes.element,
};
