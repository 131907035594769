import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import "./finished-modal.scss";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../../context/UserContext";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getWeeklyScore, getWeeklyRank, getUserScore } from "../../../services/UserService";
import { isNil } from "lodash";
import { getStartAndEndOfWeek } from "../../../helpers/helpers";
import { useScore } from "../../../context/ScoreContext";
import moment from "moment";
import AppButton from "../../common/buttons/app-button";
import CustomSvg from "../../common/custom-svg/custom-svg";
import LikeIcon from "../../../assets/icons-v2/result-popup/like.png";
import Smiley from "../../../assets/icons-v2/result-popup/smiley.png";
import Ok from "../../../assets/icons-v2/result-popup/ok.png";
import Balloon from "../../../assets/icons-v2/result-popup/balloon.png";
import Party from "../../../assets/icons-v2/result-popup/party.png";
import Diamond from "../../../assets/icons-v2/result-popup/diamond.png";
import ProgressBar from "../../common/progress-bar/progress-bar";
import { X } from "react-bootstrap-icons";
import FinishedFirstPlayModal from "./finished-first-play-modal";
import { getGuestPracticeScore, updateGuestPracticeScore } from "../../../services/ScoreService";
import GuessScores from "./components/GuessScores";
import useGAEvent from "../../../hooks/useGAEvent";

const PROGRESS_BAR_DURATION = 5000;

export default function FinishedModal(props) {
    const { isAuthenticated } = useAuth0();
    const { sendEvent } = useGAEvent();
    const { userState } = useUser();
    const { totalScore } = useScore();
    const currentDate = moment().format("M/DD/YYYY");
    const { endOfWeek } = getStartAndEndOfWeek(currentDate);
    const [isMouseDown, setIsMouseDown] = useState(false);

    /**
     * Gets time left in a week from now
     *
     * @param {date} endOfWeek - the end date of the current week
     * @param {date} currentDate - the current day
     * @returns {string} the time left from now with text
     *
     */
    const getTimeLeftInWeek = (endOfWeek, currentDate) => {
        const daysLeftInWeek = moment(endOfWeek).diff(currentDate, "days");
        const hoursLeftInWeek = moment(endOfWeek).diff(currentDate, "hours");
        const minutesLeftInWeek = moment(endOfWeek).diff(currentDate, "minutes");

        if (daysLeftInWeek > 0) {
            return `${daysLeftInWeek} more days to improve your rank!`;
        } else if (hoursLeftInWeek > 0) {
            return `${hoursLeftInWeek} more hours to improve your rank!`;
        } else {
            return `${minutesLeftInWeek} more minutes to improve your rank!`;
        }
    };

    // Update practice scores
    const updateScores = useMutation({
        mutationFn: (value) => updateGuestPracticeScore({ userId: value.userId, scoreIds: value.scoreIds }),
    });

    // Get practice scores
    const practiceScores = useQuery({
        queryKey: ["practice-scores", userState?.id],
        queryFn: () => getGuestPracticeScore({ userId: userState?.id, showViewed: false }),
        enabled: isAuthenticated && props.show,
        onSuccess: (data) => {
            if (isAuthenticated) {
                const filteredScores = data?.data?.scores?.filter(
                    (score) => !score.Score.viewed_practice_points
                );

                const scoreIds = filteredScores?.map((fs) => fs.Score.id);

                if (filteredScores.length) {
                    updateScores.mutate({ userId: userState?.id, scoreIds });
                }
            }
        },
    });

    // Get daily scores
    const userScores = useQuery({
        queryKey: [
            "user-scores-list",
            isAuthenticated,
            userState,
            userState?.id,
            userState?.current_area_id,
            props.show,
        ],
        queryFn: () => getUserScore(userState?.id, userState?.current_area_id, true),
        enabled: isAuthenticated && props.show,
    });

    const weeklyScoreQuery = useQuery({
        queryKey: ["weekly_score", userState?.id, userState?.current_area_id],
        queryFn: () =>
            getWeeklyScore(userState?.id, userState?.current_area_id, new Date().toLocaleDateString(), "all"),
        enabled: isAuthenticated && !isNil(userState?.id) && !isNil(userState?.current_area_id) && props.show,
        placeholderData: () => {
            return {
                weekly_score: 0,
            };
        },
    });

    const weeklyRankQuery = useQuery({
        queryKey: ["weekly_rank", userState?.id, userState?.current_area_id],
        queryFn: () => getWeeklyRank(userState?.id, userState?.current_area_id, "all"),
        enabled: isAuthenticated && !isNil(userState?.id) && !isNil(userState?.current_area_id) && props.show,
        placeholderData: () => {
            return {
                current_rank: null,
                total_rank: 0,
            };
        },
    });

    useEffect(() => {
        sendEvent("final_score_view_registered", {
            userScore: totalScore,
            numberOfGuesses: props.orderedScores.length,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Returns the modal information based on the score.
     *
     * @param {number} score - The final score.
     * @param {number} propertiesGuessed - The number of properties guessed.
     * @returns {object} - An object containing feedback and an icon component.
     */
    const iconSize = { height: 60, width: 60 };

    const getModalTitle = (score, propertiesGuessed) => {
        const maxScore = 100 * propertiesGuessed;
        const scorePercent = score / maxScore;

        if (scorePercent < 0.33) {
            return {
                feedback: "We all gotta start somewhere!",
                icon: <CustomSvg src={LikeIcon} size={iconSize} />,
            };
        } else if (scorePercent >= 0.33 && scorePercent < 0.5) {
            return {
                feedback: "You’re making progress. Keep it up!",
                icon: <CustomSvg src={Smiley} size={iconSize} />,
            };
        } else if (scorePercent >= 0.5 && scorePercent < 0.66) {
            return {
                feedback: "Solid effort today!",
                icon: <CustomSvg src={Ok} size={iconSize} />,
            };
        } else if (scorePercent >= 0.66 && scorePercent < 0.83) {
            return {
                feedback: "Strong performance!",
                icon: <CustomSvg src={Balloon} size={iconSize} />,
            };
        } else if (scorePercent >= 0.83 && scorePercent < 1) {
            return {
                feedback: "Uncanny!",
                icon: <CustomSvg src={Party} size={iconSize} />,
            };
        } else if (scorePercent >= 1) {
            return {
                feedback: "Perfect Game!",
                icon: <CustomSvg src={Diamond} size={iconSize} />,
            };
        } else {
            return {
                feedback: "We all gotta start somewhere!",
                icon: <CustomSvg src={LikeIcon} size={iconSize} />,
            };
        }
    };

    const propertiesGuessed = props.orderedScores.length;
    const modalInfo = getModalTitle(totalScore, propertiesGuessed);

    // Loading state of all queries combined
    const isCollectedLoading =
        weeklyRankQuery?.isLoading || weeklyScoreQuery?.isLoading || practiceScores?.isLoading;

    // Don't show component if data is still fetching
    if (isCollectedLoading) {
        return null;
    }

    // If user has unseen practice points score, return this component
    if (practiceScores?.data?.data?.scores?.length > 0) {
        return (
            <FinishedFirstPlayModal
                practiceScores={practiceScores?.data?.data}
                userScores={userScores?.data}
                feedback={modalInfo.feedback}
                icon={modalInfo.icon}
                show={props.show}
                handleClose={() => props.handleClose()}
                totalScore={totalScore ?? 0}
                rank={weeklyRankQuery?.data?.current_rank ?? 0}
            />
        );
    }

    // If user has no unseen practice points score, return this component
    return (
        <div onTouchStart={() => setIsMouseDown(true)} onTouchEnd={() => setIsMouseDown(false)}>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                centered
                backdrop
                data-cy="finished-modal"
                className="modal-container-finished"
                id="finished-modal"
            >
                <div className={"close-icons"}>
                    <X className="close" size={30} onClick={() => props.handleClose()} />
                </div>
                <Modal.Body className="px-4">
                    <div className="modal-feedback">{modalInfo.feedback}</div>
                    <div className="tw-flex tw-justify-center">{modalInfo.icon}</div>
                    <GuessScores scores={props.orderedScores} />
                    <div className="points">+{totalScore} points</div>
                    <div className="time-left">{getTimeLeftInWeek(endOfWeek, currentDate)}</div>
                    <AppButton onClick={props.handleClose} className="share-buttons">
                        See Leaderboard
                    </AppButton>
                </Modal.Body>
                <ProgressBar
                    duration={PROGRESS_BAR_DURATION}
                    isMouseDown={isMouseDown}
                    onComplete={props.handleClose}
                />
            </Modal>
        </div>
    );
}

FinishedModal.propTypes = {
    show: PropTypes.bool.isRequired,
    orderedScores: PropTypes.array,
};
