module.exports = {
    SECTIONS: [
        {
            header: "What is PriceMe Classic?",
            paragraphs: [
                "PriceMe is the daily home price guessing game for real estate enthusiasts. It’s a fun way for current and aspiring homeowners to learn about the real estate market and connect with their friends.",
                "If you love looking at homes for sale online, or if you love daily games, you’ll love PriceMe.",
            ],
        },
        {
            sub_header: "How it works",
            paragraphs: [
                "Each day, players pick a location and guess the price of three homes that recently sold nearby. The closer their guess, the more points they get - up to 100 points per guess.",
                "Players can unlock 3 extra guesses per day by picking a property and challenging their friends to beat their score. ",
                "Right now, PriceMe only has properties in the greater Chicagoland area, but we’ll be expanding soon! Anyone can play, no matter where you are.",
            ],
        },
        {
            header: "About PriceMe Classic",
            paragraphs: [
                "PriceMe was created by a former real estate brokerage owner based on a game he used to play with his new agents. The goal back then was to help them learn the market, but they soon became super competitive and just wanted to win!",
                "We also realized over the years that our home buyer and seller clients were already playing a version of this game on their own. They’d walk around their neighborhoods, look at real estate for-sale signs, and try to guess the prices - then check online to see if they were right.",
                "We created PriceMe to be a fun way to learn about real estate in just a few minutes per day. We hope it brings you a little joy during your morning commute, on your lunch break, or any time you need a little break from your day.",
            ],
        },
        {
            header: "How to play PriceMe Classic - a step-by-step guide",
        },
        {
            sub_header: "First, play a practice round",
            custom_paragraph:
                "<p>Guessing home prices can be tricky, so we created a <b>Practice Mode</b> of the game to help you get the hang of it. <i>Until you create an account and log in, you will only be able to access Practice Mode.</i></p>",
            ordered_list: [
                "Start the practice round: To get started, just tap ‘Play Practice Round’ on the popup.",
                "Check out the first property: you can swipe through photos, view basic and detailed listing info, and see where the property is located on the map.",
                "Guess the price: once you have a guess, enter the price. Hold up! You’ll see a helpful hint just above the guess input. It’ll tell you whether your guess is too high or too low. You’ll have about 10 seconds to type different guesses before the hint goes away, so enter as many as you can!",
                "Get your score: Once you feel good about the price, hit submit. The Guess Results popup will give you a score between 0 and 100.",
                "Keep going! You’ll get a total of 3 practice properties to guess",
                "Register: When you’re done, you’ll see your final score. You can register to add your score to the leaderboard.",
                "Keep playing! Now that you’ve completed your Practice Round, it’s time to head to the big leagues.",
            ],
            paragraph: "Practice round notes:",
            unordered_list: [
                "You can’t pick your location in Practice Mode. Our robots pick the 3 properties that had the highest average score from the week before.",
                "Practice Mode properties are only updated once per week, instead of every day.",
            ],
        },
        {
            sub_header: "Challenge Mode",
            paragraphs: ["Once you’ve completed your Practice Round and registered, the real fun begins."],
            ordered_list: [
                "Choose a location: you can enter a neighborhood, landmark, or specific address. Choose the same location every day to master your market…or switch it up to see more variety?",
                "Guess the price: Challenge Mode works the same way as Practice Mode, except there are no hints. You can do this!",
                "Challenge a friend: after you get your score, you have the option to challenge a friend to beat our score. This is the heart of Challenge Mode! Tap the ‘Challenge a friend’ button to share a link to the property you just guessed. Your friend will have a chance to top your score.",
            ],
            nested_list: [
                "If your friend wins your challenge, the points they scored get added to their daily total score as a bonus property.",
                "If you win, you unlock a bonus property, which you can now guess to earn extra points.",
                "Players can unlock up to 6 total properties per day (3 daily, and 3 bonus).",
            ],
        },
        {
            header: "FAQ",
            custom_paragraph: `<p>Visit our FAQ page <a href="${window.location.origin}/faq" title="FAQ address" class="app-text-link" target="_blank" rel="noreferrer">here</a>.</p>`,
        },
        {
            header: "Strategies to win at PriceMe",
        },
        {
            sub_header: "Send a lot of challenges",
            paragraph:
                "You can send as many challenges as you want to as many people as you want. Each time someone accepts your challenge but doesn’t beat your score, you unlock an extra property for the day. Unlocking all 6 properties gives you the most chances to score points.",
        },
        {
            sub_header: "Win challenges",
            custom_paragraph:
                "<p>Another way to unlock all 6 properties is to accept and win challenges. Each time you win a challenge, the points you just scored go toward your daily total, <b>and that property becomes one of your extra properties for the day</b>.</p>",
        },
        {
            sub_header: "Accept tough challenges",
            paragraphs: [
                "Your friend sent you a challenge where they only scored 26pts…easy to beat right? Well, yes. But if you only score 35pts, then you just “wasted” one of your extra properties on only 35pts.",
                "Get this: there’s no penalty for losing a challenge. So you can accept a 90pt challenge, lose, and then try a different one. No downside. But if you score, say 92pts, and win…the extra property you just earned is now worth 92pts.",
                "So a sure-fire way to rack up the most daily points is to seek out tough challenges and keep playing until you win at least 3. Strategy!",
            ],
            custom_paragraph: `<p>Pro tip: The PriceMe elves will be scattering all kinds of challenges across the web. So check our
                <a
                    href="https://www.facebook.com/groups/pricemegame"
                    title="FAQ address"
                    class="app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Facebook group
                </a>,
                <a
                    href="https://www.instagram.com/pricemegame/"
                    title="FAQ address"
                    class="app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Instagram
                </a>,
                <a
                    href="https://x.com/pricemegame"
                    title="FAQ address"
                    class="app-text-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Twitter/X
                </a>,
                and (coming soon) daily email.
            </p>`,
        },
        {
            header: "Tips for guessing property prices",
            paragraph: "Coming soon 👀",
        },
    ],
};
