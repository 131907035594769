import React, { useState, useEffect, useMemo } from "react";
import styles from "./leaderboard.module.scss";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import { DailyStats, MyStats, WeeklyStats } from "./components";
import { useUser } from "../../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import { getAllAreas } from "../../services/AreaService";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobile } from "react-device-detect";
import { motion, AnimatePresence } from "framer-motion";
import useLocalStorage from "../../hooks/useLocalStorage";
import useRegisterCtaModal from "../../hooks/useRegisterCtaModal";
import RegisterReminderModal from "../../components/modals/register-reminder-modal/register-reminder-modal";
import useCustomLoginWithRedirect from "../../hooks/useCustomLoginWithRedirect";
import useModalState from "../../hooks/useModalState";
import AppButton from "../../components/common/buttons/app-button";
import Sticky from "react-sticky-el";
import { isEmpty, get, isNil } from "lodash";
import moment from "moment";
import { getStartAndEndOfWeek } from "../../helpers/helpers";
import { useGuestContext } from "../../context/GuestContext";
import { getScores } from "../../services/UserService";
import { useScore } from "../../context/ScoreContext";
import BonusReminderModal from "../../components/modals/bonus-reminder-modal/bonus-reminder-modal";
import useGAEvent from "../../hooks/useGAEvent";
import clsx from "clsx";
import LeaderboardMainButton from "./components/leaderboard-main-button";
import ChallengesHistory from "./components/ChallengesHistory";
import StepPulseAnimation from "../../components/common/progress-indicator/components/StepPulseAnimation";
import CustomTooltip from "../../components/common/CustomTooltip";

const Leaderboard = () => {
    const { totalScore, fetchUserDailyScore } = useScore();
    const { sendEvent } = useGAEvent();
    const { getGuestPlaysWeek } = useGuestContext();
    const currentDate = moment().tz("America/Chicago").format("M/DD/YYYY");
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(currentDate);
    const { userState, setUserState } = useUser();
    const { setModalId } = useModalState();
    const { isAuthenticated, isLoading } = useAuth0();
    const [showBonusReminderModal, setShowBonusReminderModal] = useState(true);
    const [value] = useLocalStorage("isAddedToHomeScreen");
    const [pageSwiper, setPageSwiper] = useState();
    const activeIndex = get(userState, "leaderboard_active_tab", { tab: 0, button: 0 });
    const challengeTooltipTimestamp = localStorage.getItem("challenge_tooltip_timestamp");
    const [showChallengeTooltip, setShowChallengeTooltip] = useState(false);
    const [selectedArea, setSelectedArea] = useState({
        name: "",
        value: "",
        scope: "",
    });

    const allAreas = useQuery({
        queryKey: ["areas_all"],
        queryFn: getAllAreas,
        placeholderData: () => [],
    });

    const currentArea = useMemo(
        () => allAreas?.data?.find((area) => area?.id === userState?.current_area_id),
        [allAreas?.data, userState?.current_area_id]
    );

    /**
     * Fetch the user's daily score when the user authentication is loaded.
     *
     * @param {boolean} isLoading - A boolean indicating whether the user's authentication status has been loaded'.
     */
    useEffect(() => {
        if (!isLoading) {
            fetchUserDailyScore(isAuthenticated, userState?.id, userState?.current_area_id);
            toggleChallengeTooltip();
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handles opening of add to home screen modal
    useEffect(() => {
        if (isMobile && !value && isAuthenticated) {
            const timeout = setTimeout(() => setModalId("ADD_TO_HOME_SCREEN_MODAL"), 5000);
            return () => clearTimeout(timeout);
        }
    }, [setModalId, value, isAuthenticated]);

    const { openRegCta, setOpenRegCta, handleRegister } = useRegisterCtaModal({ delay: 1500 });
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();

    // Weekly scores per area
    const weeklyUserScores = useQuery({
        queryKey: [
            "weekly_user_scores",
            userState?.id,
            userState?.current_area_id,
            endOfWeek,
            startOfWeek,
            selectedArea.value,
            selectedArea.scope,
        ],
        queryFn: () =>
            getScores(
                userState?.id,
                selectedArea.value ?? userState?.current_area_id,
                endOfWeek,
                startOfWeek,
                selectedArea.scope
            ),
        enabled: !!userState?.id && (!!userState?.current_area_id || !!selectedArea.value) && isAuthenticated,
        placeholderData: () => {
            return {
                data: {
                    scores: getGuestPlaysWeek(),
                },
            };
        },
        staleTime: 100,
    });

    // Sets initial dropdown value
    useEffect(() => {
        if (!selectedArea.name || !selectedArea.value) {
            if (currentArea) {
                const currentAreaRegion = allAreas.data.filter(
                    (area) => area?.id === currentArea.parent_area_id
                );

                if (!isEmpty(currentAreaRegion)) {
                    setSelectedArea({
                        name: currentAreaRegion[0]?.name ?? "",
                        value: currentAreaRegion[0]?.id ?? "",
                        scope: currentAreaRegion[0]?.area_scope_type ?? "regional",
                    });
                } else {
                    setSelectedArea({
                        name: currentArea?.name ?? "",
                        value: currentArea?.id ?? "",
                        scope: currentArea?.area_scope_type ?? "local",
                    });
                }
            } else {
                setSelectedArea({
                    name: allAreas?.data[0]?.name ?? "",
                    value: allAreas?.data[0]?.id ?? "",
                    scope: allAreas?.data[0]?.area_scope_type ?? "local",
                });
            }
        }
    }, [selectedArea, allAreas, currentArea]);

    /**
     * Handles the click event for the registration action, triggering the registration process.
     *
     * @param {Object} event - Event object containing event data
     *
     * @returns {void}
     */
    const registerClickHandler = (event) => {
        customLoginWithRedirect({
            authorizationParams: {
                redirect_uri: window.location.origin + "/classic",
                screen_hint: "signup",
            },
            appState: {
                toggleProfile: false,
                event,
            },
        });

        sendEvent(event.name, {
            isAuthenticated: false,
            userRank: 0,
            finalScore: event.final_score,
            referralCode: event.referral_code,
        });
    };

    /**
     * Handles the click event for a tab, updating the active tab index and optionally navigating the associated swiper slider.
     *
     * @returns {void}
     */
    const handleTabClick = (tabIndex) => {
        if (activeIndex?.tab === tabIndex) {
            return;
        }

        if (pageSwiper) {
            const isFromYourStatsTab = activeIndex?.tab === 2 && tabIndex === 0;

            setUserState({ ...userState, leaderboard_active_tab: { ...activeIndex, tab: tabIndex } });

            if (isFromYourStatsTab) {
                pageSwiper.slideTo(activeIndex?.button);
            } else {
                pageSwiper.slideTo(tabIndex);
            }
        }

        // Close the challenge tooltip in Challenge tab
        if (tabIndex === 3) {
            setShowChallengeTooltip(false);
        } else {
            toggleChallengeTooltip();
        }
    };

    /**
     * Toggles the visibility of the challenge tooltip based on the last time it was shown.
     * If the tooltip has not been shown today, it will be displayed and the timestamp will be updated.
     */
    const toggleChallengeTooltip = () => {
        // Check if the challenge tooltip has already been shown today, if not show it
        const challengeTooltipTimestampDiff = moment(challengeTooltipTimestamp).diff(moment(), "days");

        if (isNil(challengeTooltipTimestamp) || challengeTooltipTimestampDiff !== 0) {
            setShowChallengeTooltip(true);
            localStorage.setItem("challenge_tooltip_timestamp", moment().format());
        }
    };

    /**
     * Handles the click event for a range button, updating the active range index and navigating the associated swiper slider.
     *
     * @returns {void}
     */
    const handleRangeClick = (rangeIndex) => {
        if (activeIndex?.button === rangeIndex) {
            return;
        }

        if (pageSwiper) {
            setUserState({ ...userState, leaderboard_active_tab: { ...activeIndex, button: rangeIndex } });
            pageSwiper.slideTo(rangeIndex);
        }
    };

    return (
        <div className={styles.container + " px-4"}>
            <h3 className="leaderboard-title">Leaderboard</h3>

            <div className="tab-buttons mb-3">
                <button
                    className={clsx(activeIndex?.tab === 0 && "active")}
                    onClick={() => handleTabClick(0)}
                >
                    Top Scores
                </button>
                <button
                    className={clsx(activeIndex?.tab === 2 && "active")}
                    onClick={() => handleTabClick(2)}
                >
                    Your Stats
                </button>

                {isAuthenticated && (
                    <>
                        {showChallengeTooltip && (
                            <CustomTooltip
                                animate
                                open
                                id="challenges-tooltip"
                                placement="top-end"
                                contentString="Keep track of your Challenge record here"
                                onClose={() => setShowChallengeTooltip(false)}
                                openDelay={3000}
                            />
                        )}
                        <button
                            className={clsx("d-flex", activeIndex?.tab === 3 && "active")}
                            onClick={() => handleTabClick(3)}
                        >
                            {activeIndex?.tab !== 3 && <StepPulseAnimation dotAnimation />}
                            Challenges
                        </button>
                    </>
                )}
            </div>

            <AnimatePresence mode="wait">
                {activeIndex?.tab === 0 && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.75 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.75 }}
                        transition={{ ease: "easeInOut", duration: 0.2 }}
                        className="leaderboard-scope-buttons mb-3"
                    >
                        <button
                            className={clsx(activeIndex?.button === 0 && "active")}
                            onClick={() => handleRangeClick(0)}
                        >
                            Weekly
                        </button>
                        <button
                            className={clsx(activeIndex?.button === 1 && "active")}
                            onClick={() => handleRangeClick(1)}
                        >
                            Daily
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>

            <Swiper
                allowTouchMove={false}
                effect="fade"
                fadeEffect={{
                    crossFade: true,
                }}
                modules={[EffectFade]}
                onSwiper={(swiper) => {
                    setPageSwiper(swiper);
                }}
            >
                {activeIndex?.tab === 3 ? (
                    <SwiperSlide>
                        <ChallengesHistory />
                    </SwiperSlide>
                ) : (
                    <>
                        {activeIndex.tab === 0 && activeIndex.button === 0 && (
                            <SwiperSlide>
                                <WeeklyStats
                                    areas={allAreas}
                                    selectedArea={selectedArea}
                                    setSelectedArea={setSelectedArea}
                                    weeklyUserScores={weeklyUserScores}
                                />
                            </SwiperSlide>
                        )}
                        {activeIndex.tab === 0 && activeIndex.button === 1 && (
                            <SwiperSlide>
                                <DailyStats
                                    areas={allAreas}
                                    selectedArea={selectedArea}
                                    setSelectedArea={setSelectedArea}
                                    weeklyUserScores={weeklyUserScores}
                                />
                            </SwiperSlide>
                        )}
                        {activeIndex.tab === 2 && (
                            <SwiperSlide>
                                <MyStats
                                    handleRegisterClick={() =>
                                        handleRegister(
                                            registerClickHandler({
                                                name: "leaderboard_register_tap",
                                                referral_code: localStorage.getItem("referral_code"),
                                                final_score: totalScore ?? 0,
                                            })
                                        )
                                    }
                                />
                            </SwiperSlide>
                        )}
                    </>
                )}
            </Swiper>

            <AnimatePresence mode="wait">
                {openRegCta && (
                    <RegisterReminderModal
                        open={openRegCta}
                        handleClick={() => {
                            handleRegister(() =>
                                registerClickHandler({
                                    name: "registration_reminder_modal_tap",
                                    referral_code: localStorage.getItem("referral_code"),
                                    final_score: totalScore ?? 0,
                                })
                            );
                        }}
                        handleClose={() => setOpenRegCta(false)}
                    />
                )}

                {showBonusReminderModal && isAuthenticated ? (
                    <BonusReminderModal
                        showModal={showBonusReminderModal}
                        handleClose={() => setShowBonusReminderModal(false)}
                    />
                ) : null}
            </AnimatePresence>

            <Sticky mode="bottom" positionRecheckInterval={500}>
                <div className="d-flex flex-column cta-buttons px-2 py-2">
                    {!isLoading && isAuthenticated ? (
                        <LeaderboardMainButton totalScore={totalScore} />
                    ) : (
                        <>
                            <div className="guest-register-helper-text">
                                Register to see where your score ranks.
                            </div>
                            <AppButton
                                className="register-button"
                                theme="gold"
                                onClick={() =>
                                    handleRegister(
                                        registerClickHandler({
                                            name: "leaderboard_register_tap",
                                            referral_code: localStorage.getItem("referral_code"),
                                            final_score: totalScore ?? 0,
                                        })
                                    )
                                }
                            >
                                Register Now!
                            </AppButton>
                        </>
                    )}
                </div>
            </Sticky>
        </div>
    );
};

export default Leaderboard;
