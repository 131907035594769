import React, { createContext, useState, useContext, useEffect } from "react";
import { getAwards, updateAward } from "../services/AwardService";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "./UserContext";
import moment from "moment-timezone";
import useModalState from "../hooks/useModalState";
import awards from "../components/constants/awards.constants";
import { nthNumber, getStartAndEndOfWeek } from "../helpers/helpers";
import { useNavigate } from "react-router-dom";

const AwardsContext = createContext();

const INIT_AWARDS_VALUES = { awards: [], isLoading: true, isError: false };

/**
 * Checks if day today is Monday
 */
const isMonday = moment().tz("America/Chicago").weekday() === 1;

export const AwardsProvider = ({ children }) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const { userState } = useUser();
    const [data, setData] = useState(INIT_AWARDS_VALUES);
    const { setModalId, setModalContent } = useModalState();

    /**
     * Generates a congratulatory headline based on the provided parameters.
     *
     * @param {boolean} options.isDaily - Indicates whether the achievement is for the top daily score.
     * @param {number} options.placement - The placement or rank achieved.
     *
     * @returns {string} A congratulatory headline.
     */
    const getHeadline = ({ isDaily, placement }) => {
        if (isDaily) {
            return "Nice! You won a medal for having one of the top scores of the day!";
        } else {
            return `Amazing! You won ${placement} place trophy!`;
        }
    };

    /**
     * Handles award modal next button
     */
    const handleNextAward = () => {
        setData((prev) => ({
            ...prev,
            awards: prev.awards.filter((award) => award.id !== data.awards[0].id),
        }));

        const awardsCount = data.awards?.length;

        if (awardsCount <= 1) {
            setModalId("");
            navigate("/classic");
        }
    };

    /**
     * Handles the display of awards information in a modal.
     */
    const handleShowAwards = React.useCallback(async () => {
        /**
         * Formats a date object into a text representation.
         *
         * @param {Date} date - The date to be formatted.
         * @returns {string} A string representing the formatted date in the "MMM DD, YYYY" format.
         */
        const textDateFormat = (date) => moment(date).format("MMM DD, YYYY");

        // Guard clause skip exec if isLoading, or no length
        if (data.isLoading || !data.awards.length) return;

        // Check if first award is daily
        const isDaily = data.awards[0].trophy_name.includes("DAILY");

        // Get award val from awards constant
        const award = Object.entries(awards.AWARDS)?.find(
            (award) => award[0] === data.awards[0].trophy_name
        )[1];

        const placement = nthNumber(award?.placement);
        const awardsCount = data.awards?.length;

        // Update award isViewed value to true
        await updateAward({ awardId: data.awards[0].id, isViewed: true });

        // Open awards modal
        setModalId("AWARDS_MODAL");

        const { startOfWeek } = getStartAndEndOfWeek(moment(data.awards[0].created_at).format("M/DD/YYYY"));

        // Set awards modal content
        setModalContent({
            title: getHeadline({ isDaily, placement }),
            message: `${placement} Place ${
                isDaily ? textDateFormat(data.awards[0].created_at) : `Week of ${textDateFormat(startOfWeek)}`
            }`,
            ctaTitle: awardsCount > 1 ? "Next award" : isDaily ? "Keep playing" : "Defend your title!",
            icon: award?.icon,
            cb: () => {},
        });
    }, [data.awards, data.isLoading, setModalContent, setModalId]);

    /**
     * Fetches awards data based on user authentication status.
     */
    const fetchAwards = React.useCallback(async () => {
        if (!isAuthenticated || !data.isLoading) return;

        const type = isMonday ? "weekly" : "daily";

        const awards = await getAwards({ type, userId: userState?.id, isViewed: false });

        if (awards) {
            setData((prev) => ({
                ...prev,
                awards: awards.awards,
                isLoading: false,
                isError: false,
            }));
        } else {
            setData((prev) => ({ ...prev, awards: [], isLoading: false, isError: true }));
        }
    }, [data.isLoading, isAuthenticated, userState?.id]);

    /**
     * Handles refetching of fetch awards func
     */
    const refetch = () => setData((prev) => ({ ...prev, isLoading: true }));

    /**
     * Fetch awards
     */
    useEffect(() => {
        void fetchAwards();
    }, [fetchAwards]);

    /**
     * Executes show awards func
     */
    useEffect(() => {
        void handleShowAwards();
    }, [handleShowAwards]);

    return (
        <AwardsContext.Provider value={{ data, refetch, handleNextAward }}>{children}</AwardsContext.Provider>
    );
};

export const useAwards = () => useContext(AwardsContext);
