import CustomSvg from "../components/common/custom-svg/custom-svg";
import StarIcon from "../assets/icons-v2/result-popup/star-icon.png";
import BullsEye from "../assets/icons-v2/result-popup/target.png";
import Trophy from "../assets/icons-v2/result-popup/trophy.png";
import TrophyStar from "../assets/icons-v2/result-popup/trophystar.png";
import InRange from "../assets/icons-v2/result-popup/inrange.png";
import OnBoard from "../assets/icons-v2/result-popup/onboard.png";
import PropTypes from "prop-types";

const FeedbackIcons = ({ userScore, iconSize = 60 }) => {
    // We need to round the percentage to the nearest whole number
    userScore = parseFloat(userScore.toFixed(0));
    let icon;

    const size = { height: iconSize, width: iconSize };

    if (userScore >= 95) {
        icon = <CustomSvg src={BullsEye} size={size} />;
    } else if (userScore >= 90) {
        icon = <CustomSvg src={Trophy} size={size} />;
    } else if (userScore >= 80) {
        icon = <CustomSvg src={TrophyStar} size={size} />;
    } else if (userScore >= 60) {
        icon = <CustomSvg src={InRange} size={size} />;
    } else if (userScore >= 35) {
        icon = <CustomSvg src={OnBoard} size={size} />;
    } else {
        icon = <CustomSvg src={StarIcon} size={size} />;
    }
    return <div className="tw-flex tw-justify-center">{icon}</div>;
};

FeedbackIcons.propTypes = {
    userScore: PropTypes.number.isRequired,
    iconSize: PropTypes.number,
};

export default FeedbackIcons;
