import { useAuth0 } from "@auth0/auth0-react";

/**
 * A custom React hook for handling user logout with Auth0.
 *
 * @returns {Object} An object containing the `logoutUser` function.
 */
const useLogout = () => {
    const { logout } = useAuth0();

    /**
     * Logs the user out using Auth0 and redirects the window's origin
     */
    const logoutUser = () => {
        localStorage.clear();

        logout({
            logoutParams: {
                returnTo: window.location.origin + "/classic",
            },
        });
    };

    return { logoutUser };
};

export default useLogout;
