import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useFocus } from "./FocusContext";

const backgroundColors = {
    default: "#FFFFFF",
    correct: "#AD6BFF",
    incorrect: "#D9D9D9",
    close: "#FAB85F",
};

const animationVariants = {
    idle: {
        rotateX: 0, // Start with no vertical rotation
    },
    flip: ({ delay, backgroundColor }) => ({
        rotateX: 360, // Rotate vertically halfway to create the "flip" effect
        backgroundColor,
        transition: {
            duration: 0.5,
            ease: [0.4, 0.0, 0.2, 1], // Wordle-like easing
            delay, // Apply the delay dynamically
        },
    }),
    strobe: {
        scale: [1, 1.15, 1],
        transition: "spring",
    },
};

const Tile = ({
    mode = "default",
    value,
    onClick,
    animationDelay,
    onAnimationComplete,
    columnIndex,
    isFocused,
    rowIndex,
    puzzleTooltipData,
    setPuzzleTooltipData,
}) => {
    const { setFocusedIndex } = useFocus();
    const animateFlip = mode !== "default" ? "flip" : !isEmpty(value) ? "strobe" : "idle";
    const customProperties = {
        delay: animationDelay,
        backgroundColor: backgroundColors[mode],
    };

    const handleClick = (e) => {
        onClick(e);
        setFocusedIndex(columnIndex);
        if (["close", "correct"].includes(mode)) {
            setPuzzleTooltipData({
                ...puzzleTooltipData,
                [`${mode}`]: {
                    columnIndex,
                    rowIndex,
                    columnValue: value,
                    hidden: false,
                },
            });
        }
    };

    return (
        <motion.div
            initial="idle"
            className={clsx(
                "tw-flex tw-h-[30px] tw-w-[30px] tw-justify-center tw-items-center tw-text-2xl tw-font-medium tw-rounded tw-border tw-bg-[#FFFFFF]",
                {
                    "tw-border-[#F69929] tw-border-[2.5px] tw-relative": isFocused,
                    "tw-border-[#2E2E2E]": !isFocused,
                }
            )}
            variants={animationVariants}
            custom={customProperties}
            animate={animateFlip}
            onClick={handleClick}
            onAnimationComplete={onAnimationComplete}
            data-tooltip-id={`tile-tooltip-${rowIndex}-${columnIndex}`}
        >
            {value ?? ""}
            {isFocused && (
                <span className="tw-absolute tw-bottom-1 tw-left-1 tw-right-1 tw-h-[1px] tw-bg-black tw-animate-blink"></span>
            )}
        </motion.div>
    );
};

Tile.propTypes = {
    startAnimation: PropTypes.bool.isRequired,
    columnIndex: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    puzzleTooltipData: PropTypes.object.isRequired,
    setPuzzleTooltipData: PropTypes.func.isRequired,
    animationDelay: PropTypes.number,
    onClick: PropTypes.func,
    mode: PropTypes.string,
    value: PropTypes.string,
    isFocused: PropTypes.bool,
};

export default Tile;
