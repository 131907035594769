/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./startup-screen.scss";
import { getRank, hasSelectLocationTimestampToday, isSameDayAsTimestamp } from "../../helpers/helpers";
import { isEmpty, isNil, isNull, isFinite, get } from "lodash";
import { useUser } from "../../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import DataQuery from "../../components/common/data-query/data-query";
import {
    getUserGuessesPerPeriod,
    getWeeklyRank,
    getWeeklyScore,
    getTitleText,
    getDailyProperties,
    getUserChallengeResults,
} from "../../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";
import { STARTUP_TITLE_TEXT } from "../../lang/startup-title-text.lang";
import { TOAST_MESSAGES } from "../../lang/toast-messages.lang";
import moment from "moment";
import ActivityFeed from "../../components/common/activity-feed/activity-feed";
import AboutPriceMe from "../../components/common/AboutPriceMe";
import { useGuestContext } from "../../context/GuestContext";
import useModalState from "../../hooks/useModalState";
import useCustomLoginWithRedirect from "../../hooks/useCustomLoginWithRedirect";
import AppButton from "../../components/common/buttons/app-button";
import { useAwards } from "../../context/AwardsContext";
import useGAEvent from "../../hooks/useGAEvent";
import houseGroupIcon from "../../assets/icons-v2/houses-group-icon.png";
import { useReferralBonus } from "../../context/ReferralBonusContext";
import { useChallenge } from "../../context/ChallengeContext";
import { toastAndRedirect } from "../../helpers/toastAndRedirect";
import StartScreenChallengerResultsModal from "../../components/modals/StartScreenChallengerResultsModal";
import Sticky from "react-sticky-el";

export default function StartupScreen(props) {
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();
    const { guestPlay, getGuestPlaysToday, totalGuestPlayScoreWeek } = useGuestContext();
    const initialStartupModalTimestamp = localStorage.getItem("startup_modal_timestamp");
    const [startupModalTimestamp, setStartupModalTimestamp] = useState(initialStartupModalTimestamp);
    const { isAuthenticated, isLoading } = useAuth0();
    const [startButtonText, setStartButtonText] = useState("Start Game");
    const { userState, hasFetchedData, setUserState } = useUser();
    const { isOpen, setModalId } = useModalState();
    const [buttonDrawerHeight, setButtonDrawerHeight] = useState(0);
    const [openStartScreenChallengerResultsModal, setOpenStartScreenChallengerResultsModal] = useState(false);
    const [delayModal, setDelayModal] = useState(false);
    const buttonDrawerRef = useRef(null);
    const { data: awardsData } = useAwards();
    const { sendEvent } = useGAEvent();
    const { referredUsers } = useReferralBonus();
    const { challengeQuery, isOwnChallenge, isInvalidChallengURL, isChallengeURL } = useChallenge();
    const hasChallengeIssue = isOwnChallenge || isInvalidChallengURL;

    const hasNoCurrentLocation = isNil(userState?.current_location) || isEmpty(userState?.current_location);
    const isNewDay = !isSameDayAsTimestamp();

    /**
     * Send a GA event after page and auth0 loading
     */
    useEffect(() => {
        if (!isLoading) {
            sendEvent("start_page_load", {
                isAuthenticated,
                challengeStatus: isChallengeURL ? "challenge_received" : "normal_start",
            });
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Listen for local storage changes for startup modal timestamp and set to state
     */
    useEffect(() => {
        /**
         * Handles setting of startup modal timestamp to state from local storage
         */
        const handleStorage = () => {
            const refreshedStartupModalTimestamp = localStorage.getItem("startup_modal_timestamp");
            setStartupModalTimestamp(refreshedStartupModalTimestamp);
        };

        window.addEventListener("storage", handleStorage());
        return () => window.removeEventListener("storage", handleStorage());
    }, [isOpen]);

    useEffect(() => {
        if (buttonDrawerRef?.current) {
            setButtonDrawerHeight(buttonDrawerRef.current.offsetHeight);
        }
    }, []);

    // After the page loads and the location is available, check the history state if this is a redirect from the daily properties guard
    useEffect(() => {
        if (get(window, "history.state.failed_guard", "") === "HasSelectedLocation") {
            toast.error(TOAST_MESSAGES.LOCATION.NO_SELECTED, { id: "LOCATION.NO_SELECTED" });

            window.history.replaceState("failed_guard", null);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * React hook to control the display of a startup modal based on user authentication and data loading status.
     *
     * @param {boolean} isAuthenticated - Indicates if the user is authenticated (logged in).
     * @param {boolean} isLoading - Indicates if the data is still loading.
     * @param {boolean} hasFetchedData - Indicates if the necessary data has been fetched.
     * @param {Object} userState - The user object stored in the User context. Can be null if no user data is available.
     * @param {Function} setShowStartupModal - A function to set the state of the startup modal.
     */
    useEffect(() => {
        const startupModalTimestampDiff = moment(startupModalTimestamp).diff(moment(), "days");
        // If the user is authenticated and data is not loading, check if the 'show_startup_modal' property is true in the userState object
        if (isAuthenticated) {
            if (!isLoading) {
                if (!awardsData.isLoading && !awardsData.awards?.length) {
                    // If user has seen not seen the modal for the day then show
                    if (
                        !isChallengeURL &&
                        (isNil(startupModalTimestamp) || startupModalTimestampDiff !== 0) &&
                        userState?.show_startup_modal
                    ) {
                        setModalId("STARTUP_MODAL");
                    }

                    // If the show_location_modal is set in the local storage, show the Select Location modal and remove it
                    if (!isNil(localStorage.getItem("show_location_modal"))) {
                        localStorage.removeItem("show_location_modal");
                        setModalId("SELECT_LOCATION_MODAL");
                    }
                }
            }
        } else {
            // Check if data is not loading
            if (!isLoading) {
                // If the user is not authenticated or data is loading, show the startup modal if data has not been fetched yet and if user has seen startup modal for the day
                if (
                    !isChallengeURL &&
                    (isNil(startupModalTimestamp) || startupModalTimestampDiff !== 0) &&
                    userState?.show_startup_modal
                ) {
                    setModalId("STARTUP_MODAL");
                }

                // Set the guest user data if there is no user state and fetched data
                if (!hasFetchedData && !userState) {
                    setUserState(null, false);
                }
            }
        }

        // Remove the startup modal when redirecting from the daily properties guard
        if (
            !isNil(window.history?.state?.usr?.failed_guard) &&
            window.history.state.usr.failed_guard &&
            isOpen("STARTUP_MODAL")
        ) {
            setModalId("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isAuthenticated,
        isLoading,
        isOpen,
        awardsData.isLoading,
        awardsData.awards,
        startupModalTimestamp,
        userState,
    ]);

    /**
     * Open bonus earned modal
     */
    useEffect(() => {
        const startupModalTimestampDiff = moment(startupModalTimestamp).diff(moment(), "days");
        const settingStartupModal = isNil(startupModalTimestamp) || startupModalTimestampDiff !== 0;

        if (
            isAuthenticated &&
            !awardsData.isLoading &&
            referredUsers.data?.length > 0 &&
            !awardsData?.awards?.length &&
            !settingStartupModal &&
            !isOpen("STARTUP_MODAL")
        ) {
            setModalId("BONUS_EARNED_MODAL");
        }
    }, [
        isAuthenticated,
        awardsData.isLoading,
        awardsData?.awards,
        startupModalTimestamp,
        referredUsers.data?.length,
        setModalId,
        isOpen,
    ]);

    const retryCallback = (failureCount, error) => {
        if (error.response?.status === 404) {
            // Retry only if the error is 404 (User not found)
            // Usually happens after user registration
            return true;
        }
        return false;
    };

    const retryDelayCallback = (attempt) => Math.min(1000 * 2 ** attempt, 30000); // Exponential backoff

    const weeklyScoreQuery = useQuery({
        queryKey: ["weekly_score", userState?.id, userState?.current_area_id],
        queryFn: () => getWeeklyScore(userState?.id, userState?.current_area_id, undefined, "all"),
        enabled:
            isAuthenticated &&
            !isNil(userState?.id) &&
            !isNil(userState?.current_area_id) &&
            !!userState?.current_area_id,
        placeholderData: () => {
            return {
                weekly_score: 0,
            };
        },
        retry: retryCallback,
        retryDelay: retryDelayCallback,
    });

    const weeklyRankQuery = useQuery({
        queryKey: ["weekly_rank", userState?.id, userState?.current_area_id],
        queryFn: () => getWeeklyRank(userState?.id, userState?.current_area_id, "all"),
        enabled:
            isAuthenticated &&
            !isNil(userState?.id) &&
            !isNil(userState?.current_area_id) &&
            !isNil(userState?.show_score_leaderboard) &&
            userState.show_score_leaderboard &&
            !!userState?.current_area_id,
        placeholderData: () => {
            return {
                current_rank: null,
                total_rank: 0,
            };
        },
        retry: retryCallback,
        retryDelay: retryDelayCallback,
    });

    const userGuesses = useQuery({
        queryKey: ["user-guesses", userState?.id],
        queryFn: () =>
            getUserGuessesPerPeriod({
                user_id: userState?.id,
            }),
        cacheTime: 0,
        enabled: isAuthenticated && !!userState?.id,
        placeholderData: () => {
            const guestPlayToday = getGuestPlaysToday(guestPlay, userState);

            return {
                data: {
                    count: {
                        total: isNil(guestPlayToday?.length) ? 0 : guestPlayToday?.length,
                    },
                },
            };
        },
    });

    const dailyProperties = useQuery({
        queryKey: ["startup-screen", userState?.id, isAuthenticated],
        queryFn: () => getDailyProperties(userState?.id, isAuthenticated),
        cacheTime: 0,
        enabled: !isLoading && isAuthenticated && !isNil(userState?.id),
    });

    const titleTextQuery = useQuery({
        queryKey: ["title", userState?.id, userState?.current_area_id],
        queryFn: () => getTitleText(userState?.id),
        enabled:
            isAuthenticated &&
            !isNil(userState?.id) &&
            !isNil(userState?.current_area_id) &&
            !isEmpty(userState?.current_area_id),
        placeholderData: () => {
            const guestWeeklyScore = totalGuestPlayScoreWeek("all");
            let titleText = STARTUP_TITLE_TEXT.START_WEEK;

            // If there is any weekly score of the guest, change the title text
            if (!isNil(guestWeeklyScore) && !isNull(guestWeeklyScore) && isFinite(guestWeeklyScore)) {
                titleText = STARTUP_TITLE_TEXT.CONTINUE;
            }

            return {
                title: titleText,
            };
        },
        retry: retryCallback,
        retryDelay: retryDelayCallback,
    });

    const userChallengeResultsQuery = useQuery({
        queryKey: ["user-challenge-results", userState?.id],
        queryFn: () => getUserChallengeResults(userState?.id),
        enabled: isAuthenticated && !isNil(userState?.id),
        cacheTime: 0,
    });
    const userChallengeResults = get(userChallengeResultsQuery, "data.data.userChallengeResults", []);

    /**
     * Handle the button click event to start the game.
     */
    const handleStartGameButtonClick = () => {
        const dailyUserGuessesCount = get(userGuesses, "data.data.count.daily", 0); // Only get the user guesses for daily properties exclude challenge
        const unlockedDailyProperties = get(dailyProperties, "data.data.properties", []).filter(
            (property) => !property.is_locked && property.type === "daily"
        );

        if (isAuthenticated) {
            // We only track users that have not played the daily properties
            if (dailyUserGuessesCount === 0) {
                sendEvent("start_button_click", { isAuthenticated });
            }

            /**
             * Show select location modal for the ff:
             * - No select location timestamp today AND the there's no daily user properties yet
             * - Even if a user has a location selected, we should still prompt them to confirm it every day PM-855
             */
            if (
                hasNoCurrentLocation ||
                (unlockedDailyProperties.length === 0 && !hasSelectLocationTimestampToday()) ||
                (isNewDay && startButtonText !== "Review game")
            ) {
                setModalId("SELECT_LOCATION_MODAL");

                return;
            }
        }

        window.location.href = `/daily-properties`;
    };

    /**
     * Render the start game button text based on the number of guesses made.
     *
     * This function returns different text for the start game button depending on the
     * number of guesses made in the game.
     */
    useEffect(() => {
        function renderStartButtonText() {
            const userGuessesLoading = userGuesses.isLoading && userGuesses.isFetching;
            const dailyPropertiesLoading = dailyProperties.isLoading && dailyProperties.isFetching;
            if (!userGuessesLoading && !dailyPropertiesLoading && !isLoading) {
                const dailyUserGuessesCount = get(userGuesses, "data.data.count.daily", 0); // Only get the user guesses for daily properties exclude challenge
                const unlockedDailyProperties = get(dailyProperties, "data.data.properties", []).filter(
                    (property) => !property.is_locked && property.type === "daily"
                );

                if (dailyUserGuessesCount === 0) return;

                if (unlockedDailyProperties.length === dailyUserGuessesCount) {
                    setStartButtonText(hasNoCurrentLocation ? "Choose Location" : "Review game");
                } else {
                    setStartButtonText("Continue Game");
                }
            }
        }

        renderStartButtonText();
    }, [userGuesses.isLoading, userGuesses.isFetching, dailyProperties, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    // Show startup modal if new user
    useEffect(() => {
        if (!isChallengeURL && isAuthenticated && userState?.show_startup_modal && userState?.is_created) {
            setModalId("STARTUP_MODAL");
        }
    }, [setModalId, isAuthenticated, userState, isChallengeURL]);

    useEffect(() => {
        if (
            isChallengeURL &&
            !isOpen("CHALLENGED_MODAL") &&
            !challengeQuery?.isFetching &&
            !hasChallengeIssue
        ) {
            setModalId("CHALLENGED_MODAL");
        }
    }, [isChallengeURL, challengeQuery?.isFetching, hasChallengeIssue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!openStartScreenChallengerResultsModal && !isEmpty(userChallengeResults)) {
            if (delayModal) userChallengeResultsQuery.refetch();

            const timer = setTimeout(
                () => {
                    setOpenStartScreenChallengerResultsModal(true);
                },
                delayModal ? 1500 : 0
            ); // delay before showing the modal again

            return () => clearTimeout(timer);
        }
    }, [openStartScreenChallengerResultsModal, userChallengeResults, delayModal, userChallengeResultsQuery]);

    if (isChallengeURL && hasChallengeIssue) {
        toastAndRedirect(
            "error",
            isOwnChallenge ? "OWN_CHALLENGE_LINK" : "INVALID_CHALLENGE_URL",
            3000,
            4000
        );
        return null;
    }

    return (
        <div className="startup-screen">
            <StartScreenChallengerResultsModal
                open={openStartScreenChallengerResultsModal}
                onClose={() => {
                    setOpenStartScreenChallengerResultsModal(false);
                    setDelayModal(true);
                }}
                results={userChallengeResults}
            />
            {isNil(userState?.current_area_id) ||
            isNull(userState?.current_area_id) ||
            isEmpty(userState?.current_area_id) ? (
                <div className="container mb-4">
                    <Row className="pt-4 mb-2 welcome-section">
                        <Col className="text-center p-0">
                            <h2 className="title mb-2" data-cy="title">
                                <div>Welcome to PriceMe!</div>
                            </h2>
                            <div className="welcome-subtitle">
                                <p className="mb-0">The Home Price</p>
                                <p className="mb-0">Guessing Game!</p>
                            </div>
                            <div className="welcome-img tw-flex tw-justify-center">
                                <img src={houseGroupIcon} alt="house-group-icon" />
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className="container mb-5">
                    <Row className="pt-4 mb-4">
                        <Col>
                            <h2 className="title" data-cy="title">
                                <DataQuery
                                    {...titleTextQuery}
                                    renderedData={(data) => {
                                        return <div className="text-center">{data?.title}</div>;
                                    }}
                                />
                            </h2>
                        </Col>
                    </Row>

                    <Row className="mb-0 user-stats-section">
                        <Col className="user-stats-col d-flex justify-content-between">
                            <div>
                                <p className="mb-0 app-text-muted">Weekly Score</p>
                                <DataQuery
                                    {...weeklyScoreQuery}
                                    renderedData={(data) => {
                                        return (
                                            <div>
                                                <h5 className="mb-0">
                                                    {isAuthenticated
                                                        ? isNil(data?.weekly_score)
                                                            ? 0
                                                            : data?.weekly_score
                                                        : totalGuestPlayScoreWeek()}
                                                </h5>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                            <div>
                                <p className="mb-0 app-text-muted">Current Rank</p>
                                {!isNil(userState?.show_score_leaderboard) &&
                                userState.show_score_leaderboard ? (
                                    <DataQuery
                                        {...weeklyRankQuery}
                                        renderedData={(data) => {
                                            return (
                                                <div>
                                                    {isAuthenticated ? (
                                                        <h5 className="mb-0">
                                                            {getRank(weeklyRankQuery.data)}
                                                        </h5>
                                                    ) : (
                                                        <button
                                                            onClick={() =>
                                                                customLoginWithRedirect({
                                                                    authorizationParams: {
                                                                        redirect_uri:
                                                                            window.location.origin +
                                                                            "/classic",
                                                                        screen_hint: "signup",
                                                                    },
                                                                    appState: {
                                                                        toggleProfile: true,
                                                                    },
                                                                })
                                                            }
                                                            className="app-text-link"
                                                        >
                                                            Register to track
                                                        </button>
                                                    )}
                                                </div>
                                            );
                                        }}
                                    />
                                ) : (
                                    "Hidden"
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}

            <div className="container mb-5">
                <div className="direction-text text-center mx-4 mb-3">
                    Guess the price of 6 properties that just sold near you.
                </div>
                <div className="direction-text text-center mx-4 mb-3">
                    Pick a property and challenge your friends to beat your score.
                </div>
                <div className="direction-text text-center mx-4">
                    Unlock all 6 daily properties by winning challenges!
                </div>
            </div>

            <ActivityFeed />

            <AboutPriceMe />

            <Sticky mode="bottom" positionRecheckInterval={50} style={{ zIndex: 2 }}>
                <div className="start-button-container overflow-hidden px-3 py-3 guess-input-container-shadow">
                    <div className="container">
                        {/** Invisible div for button drawer covering bottom contents of the page */}
                        <div className="button-drawer-padding" style={{ height: buttonDrawerHeight }} />

                        <div className="button-group">
                            {isAuthenticated ? (
                                <AppButton
                                    isDisabled={isLoading}
                                    onClick={handleStartGameButtonClick}
                                    showLoader
                                    className="w-100"
                                >
                                    {hasNoCurrentLocation ? "Choose Location" : startButtonText}
                                </AppButton>
                            ) : (
                                <AppButton
                                    onClick={() =>
                                        customLoginWithRedirect({
                                            authorizationParams: {
                                                redirect_uri: window.location.origin + "/classic",
                                                screen_hint: "signup",
                                            },
                                            appState: {
                                                toggleProfile: true,
                                                event: {
                                                    name: "register_start_page_click",
                                                    referral_code: localStorage.getItem("referral_code"),
                                                },
                                            },
                                        })
                                    }
                                    isDisabled={isLoading}
                                    variant="bordered"
                                    theme="purple"
                                    className="w-100"
                                    showLoader
                                >
                                    Register
                                </AppButton>
                            )}
                        </div>
                        <div ref={buttonDrawerRef} className="button-group-drawer">
                            {isAuthenticated ? (
                                <AppButton
                                    isDisabled={isLoading}
                                    onClick={handleStartGameButtonClick}
                                    showLoader
                                    className="w-100"
                                >
                                    {hasNoCurrentLocation ? "Choose Location" : startButtonText}
                                </AppButton>
                            ) : (
                                <AppButton
                                    onClick={() =>
                                        customLoginWithRedirect({
                                            authorizationParams: {
                                                redirect_uri: window.location.origin + "/classic",
                                                screen_hint: "signup",
                                            },
                                            appState: {
                                                toggleProfile: true,
                                                event: {
                                                    name: "register_start_page_click",
                                                    referral_code: localStorage.getItem("referral_code"),
                                                },
                                            },
                                        })
                                    }
                                    isDisabled={isLoading}
                                    variant="bordered"
                                    theme="purple"
                                    className="w-100"
                                    showLoader
                                >
                                    Register
                                </AppButton>
                            )}
                        </div>
                    </div>
                </div>
            </Sticky>
        </div>
    );
}
