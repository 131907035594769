import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../../context/UserContext";
import "./user-avatar.scss";
import useRegisterCtaModal from "../../../hooks/useRegisterCtaModal";
import useCustomLoginWithRedirect from "../../../hooks/useCustomLoginWithRedirect";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useModalState from "../../../hooks/useModalState";
import Help from "../../../assets/icons-v2/help_solid.png";
import { LIVE_VARIANT_VERSIONS } from "../../../constants/live-variant-versions";

/**
 * UserAvatar component displays user's avatar or login prompt.
 * @param {Object} props - Component props.
 * @param {Function} props.onClickProfile - Function to handle profile click.
 * @returns {JSX.Element} UserAvatar component.
 */
const UserAvatar = ({ onClickProfile }) => {
    const { isAuthenticated, user } = useAuth0();
    const { userState } = useUser();
    const { setModalId } = useModalState();
    const { handleRegister } = useRegisterCtaModal({ delay: null });
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();
    const [preferredVersion, setPreferredVersion] = useLocalStorage("live-variant-preferred-version");
    const liveVariantUrls = Object.values(LIVE_VARIANT_VERSIONS).reduce((urls, version) => {
        if (!version.disabled) {
            urls.push(version.url);
        }

        return urls;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isChooseGamePath = window.location.pathname === "/";
    const isLiveVariant = Boolean(preferredVersion && preferredVersion !== "classic") && !isChooseGamePath;

    // Update local storage when URL path changes to a valid version
    useEffect(() => {
        const path = window.location.pathname;
        const matchedVersion = liveVariantUrls.find((version) => path.includes(version));
        if (matchedVersion && matchedVersion !== preferredVersion) {
            setPreferredVersion(matchedVersion);
        }
    }, [window.location.pathname, preferredVersion, setPreferredVersion]); // eslint-disable-line react-hooks/exhaustive-deps

    // Get user initials for the avatar
    const getUserInitials = () => {
        if (userState?.first_name && userState?.last_name) {
            return `${userState.first_name[0]}${userState.last_name[0]}`.toUpperCase();
        }
        if (userState?.nickname) {
            return userState.nickname.slice(0, 2).toUpperCase();
        }
        return "";
    };

    const userInitials = isAuthenticated ? getUserInitials() : "";

    /**
     * Handles click on user profile section.
     */
    const handleUserProfileClicked = () => {
        if (isAuthenticated && !isLiveVariant) {
            onClickProfile();
        } else if (isLiveVariant) {
            setModalId("LIVE_VARIANT_HELP_MODAL");
        } else {
            handleRegister(() =>
                customLoginWithRedirect({
                    authorizationParams: {
                        redirect_uri: window.location.origin + "/classic",
                    },
                })
            );
        }
    };

    /**
     * Render the avatar content based on the authentication status.
     */
    const renderAvatarContent = () => {
        if (isChooseGamePath) return null;

        if (isAuthenticated && !isLiveVariant) {
            if (userState?.use_email_account_picture && user?.picture) {
                return <img className="user-profile" src={user?.picture} alt="user-profile" />;
            } else {
                return (
                    <div className="user-profile default-avatar">
                        <span>{userInitials}</span>
                    </div>
                );
            }
        } else if (isLiveVariant) {
            return <img src={Help} style={{ width: 30 }} alt="live-variant-help" />;
        } else if (!isChooseGamePath) {
            return <span>Log in</span>;
        }
        return null;
    };

    return (
        <div className="user-login" onClick={handleUserProfileClicked}>
            {renderAvatarContent()}
        </div>
    );
};

UserAvatar.propTypes = {
    onClickProfile: PropTypes.func.isRequired,
};

UserAvatar.defaultProps = {
    onClickProfile: () => {},
};

export default UserAvatar;
