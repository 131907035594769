import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-bootstrap/Modal";
import { get, random } from "lodash";

import { useUser } from "../../../context/UserContext";
import { getScoreFeedback } from "../../../helpers/getScoreFeedback";
import { formatPrice } from "../../../helpers/helpers";
import useGAEvent from "../../../hooks/useGAEvent";
import useCustomLoginWithRedirect from "../../../hooks/useCustomLoginWithRedirect";
import FeedbackIcons from "../../../helpers/FeedbackIcons";
import config from "../../../config";

import AppButton from "../../common/buttons/app-button";

import "./challenge-result-modal.scss";
import clsx from "clsx";

/**
 * Calculates the font size based on the length of the name.
 *
 * @param {string} name - The name whose length is considered.
 * @returns {number} The calculated font size.
 */
const calculateFontSize = (name) => {
    const baseFontSize = 29;
    const maxLength = 15;
    const minFontSize = 18;

    const decrementFactor = name.length - maxLength;

    const newFontSize = baseFontSize - decrementFactor;

    // Ensure the font size does not go below the minimum font size
    return Math.max(newFontSize, minFontSize);
};

// Randomized results text list for guest users
const guestResultTextList = [
    {
        body: "Register to get a new challenge every day",
        button: "Register",
    },
    {
        body: "Save your scores and get new challenges daily",
        button: "Save Score",
    },
    {
        body: "Register to guess prices in your neighborhood",
        button: "Play Near You",
    },
];

const guestNextRedirect = ["challenge_signup_modal", "guest_practice"];

/**
 * ChallengeResultModal component to display the result of a challenge.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Flag to show the modal.
 * @param {string} props.guessPrice - The guessed price.
 * @param {string} props.realPrice - The real price.
 * @param {string} props.challengeId - The unique identifier for the challenge.
 * @param {Object} props.challenger - The challenger object.
 * @param {Function} props.handleClose - The function to handle closing the modal.
 * @returns {JSX.Element} The rendered component.
 */
export default function ChallengeResultModal({
    show,
    guessPrice,
    realPrice,
    challengeId,
    challenger,
    handleClose,
    userGuesses = [],
    dailyProperties = [],
}) {
    const { isAuthenticated } = useAuth0();
    const { sendEvent } = useGAEvent();
    const { userState, setUserState } = useUser();
    const navigate = useNavigate();
    const [resultsHidden, setResultsHidden] = useState(true);
    const [animationKey, setAnimationKey] = useState("initial");
    const [showRegisterGuest, setShowRegisterGuest] = useState(false);
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();
    const [version] = useState(random(0, guestResultTextList.length - 1));
    const [redirectVersion] = useState(random(0, guestNextRedirect.length - 1));

    const result = getScoreFeedback(guessPrice, realPrice);
    const isMaxGuess = config.MAXIMUM_GUESS === userGuesses.length;
    const unlockedProperties = dailyProperties.filter((property) => !property.is_locked);
    const propertyIds = unlockedProperties.map((property) => property?.property_id || property?.id);
    const availableGuessCount = unlockedProperties.length;
    const guessesCount = userGuesses.reduce((count, guess) => {
        if (propertyIds.includes(guess?.property_id || guess?.PropertyId)) {
            return count + 1;
        }
        return count;
    }, 0);
    const challengerName = get(challenger, "name", "").trim();

    const getHeadlineText = () => {
        if (result.score > challenger?.points) return "Well Played!";
        if (result.score === challenger?.points) return "You've met your equal";
        return "You'll get 'em next time";
    };

    // Randomly pick a guess result text from the list
    const guestResultText = guestResultTextList[version];

    const getChallengeResult = () => {
        if (!isAuthenticated && showRegisterGuest) {
            return guestResultText.body;
        }

        if (result.score > challenger?.points) return "You Win!";
        if (result.score === challenger?.points) return "It's a tie... everybody wins!";

        const fontSize = calculateFontSize(challengerName);

        if (challengerName.length > 15) {
            const style = {
                fontSize: `${fontSize}px`,
                ...(fontSize === 18 && { wordBreak: "break-word" }), // Add word-break if fontSize is 18px
            };
            return <span style={style}>{`${challengerName} wins`}</span>;
        } else {
            return `${challengerName} wins`;
        }
    };

    const getButtonText = () => {
        let buttonText = "See Results";

        if (!resultsHidden) {
            if (isMaxGuess) {
                buttonText = "Finish";
            } else if (!isAuthenticated) {
                buttonText = showRegisterGuest ? guestResultText.button : "Next";
            } else {
                buttonText = "Keep Playing";
            }
        }

        return buttonText;
    };

    const headlineText = getHeadlineText();
    const challengeResult = getChallengeResult();

    const handleBtnClick = () => {
        if (resultsHidden) {
            setResultsHidden(false);
            setAnimationKey("reanimate");
            return;
        }

        if (!isMaxGuess && !resultsHidden && isAuthenticated) {
            sendEvent("keep_playing", {
                isAuthenticated,
                challengeId,
            });
        }

        if (!isAuthenticated) {
            const commonProps = {
                isAuthenticated,
                challengeId,
            };

            const guestChallengeRedirect = guestNextRedirect[redirectVersion];

            if (!showRegisterGuest) {
                sendEvent("next_challenge", { ...commonProps, guestChallengeRedirect });
                setUserState({
                    ...userState,
                    guest_challenge_redirect: guestChallengeRedirect,
                });

                if (guestChallengeRedirect === "guest_practice") {
                    navigate("/classic");
                } else {
                    setShowRegisterGuest(true);
                }
            } else {
                sendEvent("challenge_register_button", {
                    ...commonProps,
                    finalScore: result.score,
                    challengeGuestRegisterModalVersion: version + 1,
                });
                setUserState({
                    ...userState,
                    guest_challenge_redirect: guestChallengeRedirect,
                    challenge_register_button: version + 1,
                });
                customLoginWithRedirect({
                    authorizationParams: {
                        redirect_uri: window.location.origin + "/classic",
                        screen_hint: "signup",
                    },
                });
            }
            return;
        }

        if (isMaxGuess || (availableGuessCount > 0 && availableGuessCount === guessesCount)) {
            handleClose("finished_modal");
        } else {
            navigate("/classic");
        }
    };

    const handleSeeHistory = () => {
        setUserState({
            ...userState,
            challenge_history_selected_user: { id: challenger.user_id, nickname: challenger.name },
            leaderboard_active_tab: { ...userState?.leaderboard_active_tab, tab: 3 },
        });
        navigate("/leaderboard");
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            backdrop
            fullscreen="sm-down"
            className="modal-container-challenge-result"
        >
            <div
                className={clsx(
                    "close-icon-header pt-sm-4 mt-sm-0",
                    !resultsHidden && "pt-4 mt-4",
                    resultsHidden && "pt-5 mt-5"
                )}
            >
                <div className="header-text fs-1">
                    {resultsHidden ? result.feedback : "Challenge Results"}
                </div>
            </div>
            <Modal.Body className="px-4 pt-2">
                {!resultsHidden && <div className="modal-feedback fs-1">{headlineText}</div>}
                <FeedbackIcons userScore={result.score} iconSize={110} />
                <div
                    className={clsx(
                        "d-flex justify-content-between py-3",
                        `flex-${resultsHidden ? "column" : "row"}`
                    )}
                >
                    <motion.div
                        key={`price1-${animationKey}`}
                        className={resultsHidden ? "price" : "score"}
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.5 }}
                    >
                        <p className="fs-5">
                            {resultsHidden ? "You Guessed:" : `${challenger.name} Scored:`}
                        </p>
                        <p className="fs-2 pt-2">
                            {resultsHidden ? formatPrice(guessPrice) : `${challenger.points} pts`}
                        </p>
                    </motion.div>
                    {!resultsHidden && (
                        <div className="versus">
                            <p>VS</p>
                        </div>
                    )}
                    <motion.div
                        key={`price2-${animationKey}`}
                        className={resultsHidden ? "price" : "score"}
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 1 }}
                    >
                        <p className="fs-5">{resultsHidden ? "Actual Price:" : "You Scored:"}</p>
                        <p className="fs-2 pt-2">
                            {resultsHidden ? formatPrice(realPrice) : `${result.score} pts`}
                        </p>
                    </motion.div>
                </div>
                {!resultsHidden && !showRegisterGuest && <div className="winner-text pt-2">WINNER:</div>}
                {showRegisterGuest && (
                    <motion.div
                        key="want-more"
                        className="winner-text"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5 }}
                    >
                        WANT MORE?
                    </motion.div>
                )}
                <motion.div
                    key={`scorer-${showRegisterGuest ? "guest-result" : animationKey}`}
                    className={clsx("scorer pb-3", showRegisterGuest && "pt-1 fs-1 lh-base")}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: showRegisterGuest ? 1 : 1.5 }}
                >
                    {resultsHidden ? `${result.score} points` : challengeResult}
                </motion.div>
                <AppButton variant="solid" theme="gold" onClick={handleBtnClick} className="modal-button">
                    {getButtonText()}
                </AppButton>

                {isAuthenticated && !resultsHidden && (
                    <div className="see-history-text pt-3" onClick={handleSeeHistory}>
                        See history: you vs {challenger.name}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}

ChallengeResultModal.propTypes = {
    show: PropTypes.bool.isRequired,
    guessPrice: PropTypes.string.isRequired,
    realPrice: PropTypes.string.isRequired,
    challengeId: PropTypes.string.isRequired,
    challenger: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    userGuesses: PropTypes.array,
    dailyProperties: PropTypes.array,
};
