import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { random } from "lodash";

import AppButton from "../../common/buttons/app-button";
import CustomSvg from "../../common/custom-svg/custom-svg";
import ResponsiveWrapper from "../../common/ResponsiveWrapper";

import useCustomLoginWithRedirect from "../../../hooks/useCustomLoginWithRedirect";
import useGAEvent from "../../../hooks/useGAEvent";
import useRegisterCtaModal from "../../../hooks/useRegisterCtaModal";
import { useUser } from "../../../context/UserContext";

import Balloon from "../../../assets/icons-v2/result-popup/balloon.png";
import "./practice-results-modal.scss";

const versions = [
    {
        // ver 1
        secondaryHeaderTxt: "Want to keep playing?",
        registerText: "Register now to keep playing and get 3 new listings to guess every day",
        buttonText: "Play 3 More",
    },
    {
        // ver 2
        secondaryHeaderTxt: "Want to save your score?",
        registerText:
            "Register to save your score to the leaderboard and get 3 new listings to guess every day",
        buttonText: "Save Score",
    },
    {
        // ver 3
        secondaryHeaderTxt: "Want to guess listings near you?",
        registerText: "Register to guess 3 more properties near a location of your choice",
        buttonText: "Register",
    },
];

/**
 * Represents a modal component displaying practice results.
 * @param {Object} props - The props object containing properties for the modal.
 * @param {boolean} props.show - Indicates whether the modal should be displayed.
 * @param {Function} props.handleClose - The function to handle the modal close event.
 * @param {number} props.score - The score earned in practice.
 * @returns {JSX.Element} - JSX element representing the practice results modal.
 */
const PracticeResultsModal = ({ show, handleClose, score }) => {
    const { sendEvent } = useGAEvent();
    const { userState, setUserState } = useUser();
    const { handleRegister } = useRegisterCtaModal({ delay: 1500 });
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();
    const [modalVersion] = useState(random(0, versions.length - 1));

    const registerClickHandler = (event) => {
        sendEvent(event.name, {
            isAuthenticated: false,
            practiceFinalScore: event.final_score,
            referralCode: event.referral_code,
            guestFinalScoreModalVersion: modalVersion + 1,
        });

        setUserState({
            ...userState,
            guest_final_score_modal_version: modalVersion + 1,
        });

        customLoginWithRedirect({
            authorizationParams: {
                redirect_uri: window.location.origin + "/classic",
                screen_hint: "signup",
            },
            appState: {
                toggleProfile: false,
                event,
            },
        });
    };

    const handleSeeLeaderboard = () => {
        sendEvent("guest_final_score_see_leaderboard", {
            isAuthenticated: false,
            practiceFinalScore: score ?? 0,
            guestFinalScoreModalVersion: modalVersion + 1,
        });

        setUserState({
            ...userState,
            guest_final_score_modal_version: modalVersion + 1,
        });

        handleClose();
    };

    useEffect(() => {
        sendEvent("final_score_view_practice", {
            isAuthenticated: false,
            finalScore: score,
            referralCode: localStorage.getItem("referral_code"),
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ResponsiveWrapper
            show={show}
            onHide={handleClose}
            centered
            backdrop="static"
            className="practice-property-modal"
        >
            <Modal.Body className="pb-0">
                <div className="practice-modal-content text-center">
                    <h4 className="practice-modal-header-text mb-0 app-text-title px-4">
                        You’re getting the hang of it!
                    </h4>
                    <div className="practice-guess-icon">
                        <CustomSvg src={Balloon} size={{ height: 120, width: 120 }} />
                    </div>
                    <div className="practice-points">+{score} points</div>
                    <h5 className="regiser-text-secondary-header mb-0 app-text-title px-1 pt-3">
                        {versions[modalVersion].secondaryHeaderTxt}
                    </h5>
                    <p className="register-text mb-0 pt-3">{versions[modalVersion].registerText}</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="pb-4 pt-3">
                <AppButton
                    onClick={() => {
                        handleRegister(() =>
                            registerClickHandler({
                                name: "final_score_register_tap_practice",
                                referral_code: localStorage.getItem("referral_code"),
                                final_score: score ?? 0,
                            })
                        );
                    }}
                    className="mx-auto register-button"
                    variant="solid"
                    theme="purple"
                >
                    {versions[modalVersion].buttonText}
                </AppButton>
                <div className="close-btn text-center" onClick={handleSeeLeaderboard}>
                    See Leaderboard
                </div>
            </Modal.Footer>
        </ResponsiveWrapper>
    );
};

PracticeResultsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    score: PropTypes.number.isRequired,
};

export default PracticeResultsModal;
