/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from "react-bootstrap/Modal";
import { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { useEffect, useRef, useState } from "react";
import { syncUser } from "../../../services/UserService";
import { useUser } from "../../../context/UserContext";
import houseSoldIcon from "../../../assets/icons-v2/house-sold-icon.png";
import "./startup-modal.scss";
import AppButton from "../../common/buttons/app-button";
import useCustomLoginWithRedirect from "../../../hooks/useCustomLoginWithRedirect";
import useModalState from "../../../hooks/useModalState";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import useGAEvent from "../../../hooks/useGAEvent";
import clsx from "clsx";
import ResponsiveWrapper from "../../common/ResponsiveWrapper";
import { STARTUP_MODAL_CONTENTS } from "../../../constants/startup-modal-contents";

export default function StartupModal() {
    const { isAuthenticated, isLoading } = useAuth0();
    const { setModalId, isOpen } = useModalState();
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();
    const { userState, setUserState } = useUser();
    const swiperElRef = useRef(null);
    const [showNextBtn, setShowNextBtn] = useState(true);
    const [showHiddenContinueBtn, setShowHiddenContinueBtn] = useState(false);
    const { sendEvent } = useGAEvent();

    const showStartUpModal = userState?.show_startup_modal;

    /**
     * Sets the text for the call-to-action button based on authentication and area availability.
     * @param {Object} options - The options object.
     * @param {boolean} options.isAuthenticated - Indicates whether the user is authenticated.
     * @returns {string} The text for the call-to-action button.
     */
    const setCtaButtonText = ({ isAuthenticated }) => {
        if (!isAuthenticated) {
            return "Play Practice Round";
        }

        return "Let’s Play";
    };

    /**
     * Synchronizes user data with the updated user information and calls a callback function upon completion.
     * @param {Object} updatedUser - Object containing the updated user information.
     * @param {Function} cb - Callback function to be executed after synchronization.
     */
    const syncUserData = (updatedUser, cb) => {
        syncUser({ ...userState, ...updatedUser })
            .then((response) => {
                const { data } = response;

                if (data) {
                    setUserState(data, userState.is_created);
                }

                cb();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    /**
     * Handles the click on the register link, initiating the registration process.
     * This function uses custom login with redirect to start the registration process.
     *
     * @returns {void}
     */
    const handleRegisterLinkClick = () =>
        customLoginWithRedirect({
            authorizationParams: {
                redirect_uri: window.location.origin + "/classic",
            },
            appState: {
                toggleProfile: true,
                event: {
                    name: "tutorial_register_click",
                    referral_code: localStorage.getItem("referral_code"),
                },
            },
        });

    /**
     * Handles the click on the Let's Play button to redirect to the daily properties page
     *
     * @returns {void}
     */
    const handleLetsPlayButtonClick = () => {
        if (!isAuthenticated) {
            sendEvent("lets_play_practice", { isAuthenticated });

            window.location.href = "/daily-properties";
            return;
        }

        setModalId("SELECT_LOCATION_MODAL");

        // Perform user data synchronization with the updated show_startup_modal property
        syncUserData({ show_startup_modal: false });
    };

    // Set start up modal timestamp on open
    useEffect(() => {
        if (isOpen("STARTUP_MODAL") && isAuthenticated) {
            localStorage.setItem("startup_modal_timestamp", moment().format());
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle UI state changes based on loading and authentication status.
     *
     * @function
     */
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            setShowNextBtn(false);
            setShowHiddenContinueBtn(true);
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!showStartUpModal) {
        return null;
    }

    return (
        <ResponsiveWrapper
            show={isOpen("STARTUP_MODAL")}
            centered={true}
            keyboard={false}
            backdrop={"static"}
            data-cy="startup-modal"
            className={clsx("startup-modal", !isAuthenticated && "guest-modal")}
        >
            <>
                <Modal.Body>
                    {isAuthenticated ? (
                        <>
                            <Swiper
                                watchSlidesProgress
                                allowTouchMove={false}
                                ref={swiperElRef}
                                modules={[Navigation, Pagination, EffectFade]}
                                navigation={{
                                    nextEl: "#nextBtn",
                                    prevEl: "#prevBtn",
                                }}
                                className="swiper-container"
                                data-cy="swiper-container"
                                speed="400"
                                effect="fade"
                                fadeEffect={{
                                    crossFade: true,
                                }}
                                onReachEnd={function () {
                                    setShowNextBtn(false);
                                    setShowHiddenContinueBtn(true);
                                }}
                                onFromEdge={function () {
                                    if (
                                        this.activeIndex !== 0 &&
                                        this.activeIndex === this.slides.length - 1
                                    ) {
                                        setShowHiddenContinueBtn(false);
                                    }
                                }}
                                onSlideChange={(obj) => {
                                    if (obj.activeIndex !== 0 && obj.activeIndex === obj.slides.length - 1) {
                                        setShowNextBtn(false);
                                    } else {
                                        setShowNextBtn(true);
                                    }
                                }}
                                pagination={{
                                    el: ".swiper-pagination",
                                    type: "bullets",
                                    clickable: true,
                                }}
                            >
                                {STARTUP_MODAL_CONTENTS.map((content, index) => (
                                    <SwiperSlide key={`swiper-slide-${index}`}>
                                        <div className="slide-group d-flex flex-column justify-content-end">
                                            <h5 className="app-text-title text-center">{content.header}</h5>
                                            <div className="slide-img-wrapper my-4">
                                                <img
                                                    className={clsx("modal-icon", content.alt)}
                                                    src={content.icon}
                                                    alt={content.alt}
                                                />
                                            </div>

                                            <p className="mb-3 mx-3">
                                                <div dangerouslySetInnerHTML={{ __html: content.text }} />
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div className="swiper-pagination"></div>
                        </>
                    ) : (
                        <div className="slide-group d-flex flex-column gap-3">
                            <h5 className="app-text-title tw-text-center !tw-text-[2.4vh]">
                                Welcome to PriceMe!
                            </h5>
                            <div className="slide-img-wrapper">
                                <img
                                    className="house-sold-icon !tw-h-[13vh]"
                                    src={houseSoldIcon}
                                    alt="house-sold-icon"
                                />
                            </div>

                            <h5 className="app-text-title tw-text-center !tw-text-[2.4vh]">How to Play:</h5>
                            <ol className="tw-m-0 tw-pl-6 tw-list-decimal tw-list-inside tw-text-[2vh]">
                                <li>Guess the price of homes that just sold.</li>
                                <li>The closer your guess, the more points you get.</li>
                                <li>Challenge your friends to win more guesses</li>
                                <li>Check out the leaderboard to see how you did.</li>
                            </ol>
                            <p className="tw-m-0 tw-text-center tw-font-bold !tw-p-0 !tw-text-[2vh]">
                                First time playing?
                            </p>
                            <p className="tw-m-0 tw-text-center tw-font-bold !tw-p-0 !tw-text-[2vh]">
                                Let’s warm up with a practice round 💫
                            </p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex flex-column gap-3">
                    <AppButton
                        id="nextBtn"
                        type="button"
                        className={clsx(
                            "modal-button onboarding !tw-max-h-[7vh] !tw-flex !tw-items-center !tw-justify-center",
                            !showNextBtn && "d-none"
                        )}
                        variant="solid"
                        theme="purple"
                    >
                        Next
                    </AppButton>

                    <AppButton
                        type="button"
                        variant="solid"
                        theme="purple"
                        className={clsx(
                            "tw-mx-0",
                            "modal-button !tw-max-h-[7vh] !tw-flex !tw-items-center !tw-justify-center",
                            isAuthenticated ? "onboarding" : "startup",
                            {
                                "d-none": !showHiddenContinueBtn,
                            }
                        )}
                        id="hiddenContinueBtn"
                        onClick={handleLetsPlayButtonClick}
                    >
                        {setCtaButtonText({ isAuthenticated })}
                    </AppButton>

                    {!isAuthenticated ? (
                        <AppButton
                            onClick={handleRegisterLinkClick}
                            className="fw-normal app-text-link modal-button startup !tw-max-h-[7vh] !tw-flex !tw-items-center !tw-justify-center"
                            variant="bordered"
                            theme="purple"
                        >
                            Already registered? Log in
                        </AppButton>
                    ) : null}
                </Modal.Footer>
            </>
        </ResponsiveWrapper>
    );
}
