import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const ChallengeRedirect = () => {
    const { challenge_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (challenge_id) {
            navigate(`/classic?challenge_id=${challenge_id}`, { replace: true });
        }
    }, [challenge_id, navigate]);

    return (
        <div className="loader-container">
            <p>Redirecting to challenge...</p>
        </div>
    );
};

export default ChallengeRedirect;
